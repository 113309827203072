<mat-card id="main-card" class="mat-elevation-z4">
  <mat-card-header>
    <mat-card-title> <mat-icon fontSet="fa" fontIcon="fa-bug"></mat-icon> Langages informatiques et FrameWork</mat-card-title>
  </mat-card-header>
  <mat-card-content id="main-content">
    <div *ngFor="let language of langageList" class="langItem" fxLayout="row" fxLayout.xs="column">
      <mat-card fxFlex="100" class="mat-elevation-z2">
        <mat-card-header>
          <mat-card-title>{{language.name}}</mat-card-title>
        </mat-card-header>
        <mat-card-content fxLayoutAlign="center">
          <a href="{{language.url}}" target="_blank" fxLayoutAlign="center">
            <img mat-card-image src="{{language.image}}"/>
          </a>
        </mat-card-content>
      </mat-card>
    </div>
  </mat-card-content>
</mat-card>
