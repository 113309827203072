<div id="container">
  <div class="row" fxLayout="row" fxLayout.xs="column">
    <div class="col" fxLayout="column" fxLayout.xs="column">
      <app-profil-card class="card" id="card"></app-profil-card>
      <app-quote class="card" id="quote"></app-quote>
    </div>
    <div class="col" fxLayout="column" fxLayout.xs="column">
      <app-profil-info class="card" id="info"></app-profil-info>
    </div>
  </div>
  <div class="row" fxLayout="row" fxLayout.xs="column">
    <app-profil-competences class="card" id="competences"></app-profil-competences>
  </div>
</div>
