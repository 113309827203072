<div id="mainContainer">
  <mat-card class="mat-elevation-z4 firstRow" *ngFor="let card of cardList">
    <mat-card-header>
      <mat-card-title>{{card.title}}</mat-card-title>
      <mat-card-subtitle> {{card.subtitle}} </mat-card-subtitle>
    </mat-card-header>
    <a href="{{card.url}}" target="_blank" fxLayoutAlign="center">
      <img mat-card-image src="{{card.image}}">
    </a>
    <mat-card-content>
      <div>{{card.desc}}</div>
      <br>
      <div>{{card.benef}}</div>
    </mat-card-content>
    <!-- 
    <mat-card-actions>
      <button mat-raised-button (click)="openPreuve(card.preuveImage)" class="timeButton">
        <mat-icon fontSet="fa" fontIcon="fa-clock"></mat-icon>
      </button>
      <div id="actionHelper"></div>
      <div class="valoTime">Heures: {{card.valoTime}}</div>
    </mat-card-actions>
    -->
  </mat-card>

</div>