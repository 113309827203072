import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {DialogData} from "../portfolio/portfolio.component";

@Component({
  selector: 'app-port-folio-preuve',
  templateUrl: './port-folio-preuve.component.html',
  styleUrls: ['./port-folio-preuve.component.scss']
})

export class PortFolioPreuveComponent implements OnInit {

  imageList: string[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.imageList = this.data.imageURL;
  }

  ngOnInit(): void {
    console.log(this.imageList);
  }
}
