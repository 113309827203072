<div id="main_container">
  <table mat-table [dataSource]="valorisationList" class="mat-elevation-z4">
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef> Titre </th>
      <td mat-cell *matCellDef="let element"> {{element.title}} </td>
      <td mat-footer-cell *matFooterCellDef> Total </td>
    </ng-container>

    <ng-container matColumnDef="preuve">
      <th mat-header-cell *matHeaderCellDef> Preuve </th>
      <td mat-cell *matCellDef="let row">
        <div class="timeButton">
          <mat-icon fontSet="fa" fontIcon="fa-clock" (click)="openPreuve(row.preuveImage)"></mat-icon>
        </div>
      </td>
      <td mat-footer-cell *matFooterCellDef> </td>
    </ng-container>

    <ng-container matColumnDef="realTime">
      <th mat-header-cell *matHeaderCellDef> Heures réelles </th>
      <td mat-cell *matCellDef="let element"> {{element.realTime}} </td>
      <td mat-footer-cell *matFooterCellDef> {{getTotalHoursReal()}}h </td>
    </ng-container>

    <ng-container matColumnDef="valoTime">
      <th mat-header-cell *matHeaderCellDef> Heures valorisées </th>
      <td mat-cell *matCellDef="let element"> {{element.valoTime}} </td>
      <td mat-footer-cell *matFooterCellDef> {{getTotalHoursValo()}}h </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
  </table>
  <img class="mat-elevation-z4" src="../../assets/formations/-Global%20379h.png">
</div>