import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PortFolioPreuveComponent } from "../port-folio-preuve/port-folio-preuve.component";

@Component({
  selector: "app-recap-heures",
  templateUrl: "./recap-heures.component.html",
  styleUrls: ["./recap-heures.component.scss"],
})
export class RecapHeuresComponent implements OnInit {
  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  displayedColumns = ["title", "preuve", "realTime", "valoTime"];
  valorisationList = [
    {
      title: "HackAthon WireHack 2019-2020",
      preuveImage: [
        "../../assets/wireHack2020/presentationAudit.jpeg",
        "../../assets/wireHack2020/hackatonPreuve.jpeg",
      ],
      realTime: "24h",
      valoTime: "10h",
    },
    {
      title: "CyberSecurityChallenge 2020",
      preuveImage: ["../../assets/CyberSecurityChallenge/cyber.png"],
      realTime: "24h",
      valoTime: "10h",
    },
    {
      title: "Formations Python, Data-Science et Machine-Learning",
      preuveImage: [
        "../../assets/formations/init Python Analyse donnees 12h.png",
        "../../assets/formations/lib Python Data Science 10h.png",
        "../../assets/formations/machine learning 10h.png",
        "../../assets/formations/python oriente objet 6h.png",
        "../../assets/formations/python perfectionement 4h.png",
        "../../assets/formations/python 4h.png",
        "../../assets/formations/pythoon data analysis 12h.png",
        "../../assets/formations/pythoon dev 40h.png",
        "../../assets/formations/R 12h.png",
        "../../assets/formations/algo 4h.png",
      ],
      realTime: "80h",
      valoTime: "10h",
    },
    {
      title: "Formations C, C++ et C#",
      preuveImage: [
        "../../assets/formations/Cplusplus 50h.png",
        "../../assets/formations/Csharp oriente objet 20h.png",
        "../../assets/formations/Csharp 20h.png",
      ],
      realTime: "90h",
      valoTime: "10h",
    },
    {
      title: "Formations dévéloppement IOS en swift",
      preuveImage: [
        "../../assets/formations/dev iphone mvc 30h.png",
        "../../assets/formations/ios dev mobile 15h.png",
        "../../assets/formations/ios responsive 10h.png",
        "../../assets/formations/swift approfondi 20h.png",
        "../../assets/formations/swift 30h.png",
      ],
      realTime: "105h",
      valoTime: "10h",
    },
    {
      title: "Formation NodeJS",
      preuveImage: ["../../assets/formations/nodeJS 10h.png"],
      realTime: "10h",
      valoTime: "0h",
    },
    {
      title: "Formation ReactNative",
      preuveImage: ["../../assets/formations/ReactNative 30h.png"],
      realTime: "30h",
      valoTime: "0h",
    },
    {
      title: "Formation Angular",
      preuveImage: [
        "../../assets/formations/boottrap 10h.png",
        "../../assets/formations/Dev App Angular 20h.png",
      ],
      realTime: "20h",
      valoTime: "0h",
    },
    {
      title: "Formation Docker",
      preuveImage: ["../../assets/formations/Docker 8h.png"],
      realTime: "8h",
      valoTime: "0h",
    },
    {
      title: "Formation Amazon Web Services",
      preuveImage: ["../../assets/formations/AWS decouverte 5h.png"],
      realTime: "20h",
      valoTime: "0h",
    },
    {
      title: "Formation AdobeXD",
      image: "../../assets/other/adobeXD.jpg",
      preuveImage: ["../../assets/formations/adobeXD 1h.png"],
      realTime: "6h",
      valoTime: "0h",
    },
    {
      title: "Matériel informatique",
      image: "../../assets/materiel/computer.png",
      preuveImage: ["../../assets/materiel/computer.png"],
      realTime: "100h",
      valoTime: "10h",
    },
  ];

  openPreuve(preuveImage: string[]): void {
    console.log(preuveImage);
    this.dialog.open(PortFolioPreuveComponent, {
      data: {
        imageURL: preuveImage,
      },
    });
  }

  getTotalHoursReal(): number {
    let total = 0;
    for (let subject of this.valorisationList) {
      total += parseInt(
        subject.realTime.substring(0, subject.realTime.length - 1)
      );
    }
    return total;
  }

  getTotalHoursValo(): number {
    let total = 0;
    for (let subject of this.valorisationList) {
      total += parseInt(
        subject.valoTime.substring(0, subject.valoTime.length - 1)
      );
    }
    return total;
  }
}
