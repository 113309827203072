<mat-card class="info-card mat-elevation-z4">
  <mat-card-header>
    <mat-card-title id="primaryTitle">Mes informations</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <mat-divider></mat-divider>
    <mat-card-header>
      <mat-card-title>
        <mat-icon fontSet="fa" fontIcon="fa-graduation-cap"></mat-icon>
        Etudes
      </mat-card-title>
    </mat-card-header>
    <p class="subContent">
      <mat-list>
        <mat-list-item>
          <div mat-line class="date">Février 2021 - Mai 2021</div>
          <div mat-line class="subject">Stage Boston Scientific</div>
          <div mat-line class="subject">BSC Non-Employee Training IL</div>
          <div mat-line class="descriptionSubject">Stage de de Bachelor</div>
        </mat-list-item>
        <mat-list-item>
          <div mat-line class="date">Année 2018 - 2021</div>
          <div mat-line class="subject">EPHEC Informatique et Systèmes</div>
          <div mat-line class="subject">finalité Technologie de l'informatique </div>
          <div mat-line class="descriptionSubject">3e année de Bachelor</div>
        </mat-list-item>
        <mat-list-item>
          <div mat-line class="date">Année 2017 - 2018</div>
          <div mat-line class="subject">UCL Sciences Inforamtiques</div>
          <div mat-line class="descriptionSubject">réorientation en fin d'année 2018</div>
        </mat-list-item>
        <mat-list-item>
          <div mat-line class="date">Année 2016 - 2017</div>
          <div mat-line class="subject">UCL Bio-Ingénieurie</div>
          <div mat-line class="descriptionSubject">réorientation en fin d'année 2017</div>
        </mat-list-item>
        <mat-list-item>
          <div mat-line class="date">Année 2015 - 2016</div>
          <div mat-line class="subject">UCL Sciences Physiques</div>
          <div mat-line class="descriptionSubject">arrêt mi-année 2016</div>
        </mat-list-item>
      </mat-list>
    </p>
    <mat-divider></mat-divider>
    <mat-card-header>
      <mat-card-title>
        <mat-icon fontSet="fa" fontIcon="fa-globe"></mat-icon> Langues
      </mat-card-title>
    </mat-card-header>
    <p class="subContent">
      <mat-list>
        <mat-list-item>
          <div mat-line>Français</div>
          <div mat-line class="mat-list-sub">Langue maternelle</div>
        </mat-list-item>
        <mat-list-item>
          <div mat-line>Anglais</div>
          <div mat-line class="mat-list-sub">Avancé | <a href="assets/EF%20SET%20Certificate.pdf" target="_blank"> CEFR
              C2</a></div>
        </mat-list-item>
        <mat-list-item>
          <div mat-line>Néerlandais</div>
          <div mat-line class="mat-list-sub">Basique</div>
        </mat-list-item>
      </mat-list>
    </p>
    <mat-divider></mat-divider>
    <mat-card-header>
      <mat-card-title>
        <mat-icon fontSet="fa" fontIcon="fa-id-card"></mat-icon> Contact
      </mat-card-title>
    </mat-card-header>
    <p class="subContent">
      <mat-list>
        <mat-list-item (click)="sendMail()">
          <div mat-line class="contactTitle">
            <mat-icon fontSet="fa" fontIcon="fa-envelope"></mat-icon> Mail
          </div>
          <div mat-line class="mat-list-sub">guillaume@lemer.be</div>
        </mat-list-item>
        <mat-list-item>
          <div mat-line class="contactTitle">
            <mat-icon fontSet="fa" fontIcon="fa-phone"></mat-icon> Téléphone
          </div>
          <div mat-line class="mat-list-sub">+32492636797</div>
        </mat-list-item>
      </mat-list>
    </p>
  </mat-card-content>

</mat-card>