import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profil-info',
  templateUrl: './profil-info.component.html',
  styleUrls: ['./profil-info.component.scss']
})
export class ProfilInfoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  sendMail(): void {
    window.open('mailto:guillaume@lemer.be', 'blank');
  }

}
