<div class="mat-headline" id="title">
  Bienvenue sur le portfolio de Lemer Guillaume
</div>
<mat-tab-group animationDuration="5ms" mat-align-tabs="center">
  <mat-tab label="Profil">
    <app-profil></app-profil>
  </mat-tab>
  <mat-tab label="PortFolio">
    <app-portfolio></app-portfolio>
  </mat-tab>
  <!--<mat-tab label="Récapitulatif"> <app-recap-heures></app-recap-heures> </mat-tab> -->
</mat-tab-group>