import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-profil-card",
  templateUrl: "./profil-card.component.html",
  styleUrls: ["./profil-card.component.scss"],
})
export class ProfilCardComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  openLinkedin(): void {
    window.open("https://www.linkedin.com/in/guillaume-lemer/", "blank");
  }

  openGithub(): void {
    window.open("https://github.com/Arylmera", "blank");
  }

  downloadCV() {
    window.open("./assets/CV_Guillaume-Lemer_English.pdf", "blank");
  }

  downloadCVFR() {
    window.open("./assets/CV_Guillaume-Lemer_Francais.pdf", "blank");
  }
}
