<mat-card class="profile-card mat-elevation-z4">
  <mat-card-header>
    <div id="quote">
      <p>It's better for the developers to be surfing than writing code that won't be need.
        [...]</p>
      <p>
        If they went surfing, they would have fun and i would have less expensive system and fewer headaches to
        maintain.
      </p>
    </div>
    <div id="source"> Jeff Sutherland </div>
  </mat-card-header>
</mat-card>