import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PortFolioPreuveComponent } from "../port-folio-preuve/port-folio-preuve.component";

export interface DialogData {
  imageURL: string[];
}

@Component({
  selector: "app-portfolio",
  templateUrl: "./portfolio.component.html",
  styleUrls: ["./portfolio.component.scss"],
})
export class PortfolioComponent implements OnInit {
  constructor(public dialog: MatDialog) {}

  cardList = [
    {
      url: "https://www.bostonscientific.com/es-ar/home.html",
      title: "Stage de 3e baccalauréat en Science informatique",
      subtitle: "Un premier aperçu du monde du travail",
      desc: "Dans le cadre de ma dernière année de bachelier en technologie de l’informatique à l’EPHEC, j’ai eul’opportunité de réaliser un stage d’insertion professionnelle, ce stage se déroulant sur une période allantde début février à mi mai. Durant ces 5 mois, j’ai participé à la vie active d’une entreprise, pour laquellej’ai été un employé à part entière. Cette période eu pour but de me permettre de mieux me rendre compte,ainsi que de déjà pouvoir m’habituer, aux différences entre le monde professionnel et le monde étudiant.",
      benef:
        "Ce stage m’a permis de déjà avoir une petite idée de la vie en entreprise. Certes au vu des conditions sanitaires actuelles, toutes les interactions ou presque, étaient réalisées par le biais de Microsoft Teams. Mais le travail en équipe, les réunions et les différentes demandes qui découlent d’un travail en entreprise étaient bien présentes. J’ai donc pu prendre conscience du gouffre qui sépare la vie d’étudiant de la vie professionnelle. J’ai pu également apprendre bien des choses sur la manière de gérer une équipe ainsi que les demandes qui nous sont faites en entreprise, afin d’être mieux préparé à mon avenir.",
      image: "../../assets/other/BostonScientificLogo.jpg",
      preuveImage: [""],
      valoTime: "0h",
    },
    {
      url: "https://www.wirehack.me/",
      title: "HackAthon WireHack 2019-2020",
      subtitle: "Une expérience enrichissante",
      desc:
        "Le HackAthon whirehack 22019-2020 consistait en la réalisation d'un projet permettant de s'échapper d'une ville durant une attaque zombie." +
        " Durant 24 h, notre équipe a développé un site web à partir de zéro afin de pouvoir se diriger dans la ville.",
      benef:
        " Cette expérience nous a montré l'importance du travail de groupe," +
        " ainsi que la gestion du temps d'une équipe et la répartition des tâches.",
      image: "../../assets/wireHack2020/wirehack20192020.jpg",
      preuveImage: [
        "../../assets/wireHack2020/presentationAudit.jpeg",
        "../../assets/wireHack2020/hackatonPreuve.jpeg",
      ],
      valoTime: "24h",
    },
    {
      url: "https://www.cybersecuritychallenge.be/",
      title: "CyberSecurityChallenge 2020",
      subtitle: "Une entrée dans le monde de la sécurité",
      desc:
        "Cette compétition de sécurité informatique, basée sur le principe de Capture The Flag, est un jeu constitué de challenges réalisé par des équipes de 4 étudiants au maximum." +
        " Les équipes devront résoudre en un temps imparti des challenges recouvrant une large variété de problèmes de sécurité: cryptographie, reverse engineering, sécurité réseaux, sécurité web ou mobile, analyse forensique et de réseaux sans fil." +
        " La complexité des challenges varie de évident à vraiment complexe",
      benef:
        "Cette expérience nous a permis de faire nos premiers pas dans le monde de la sécurité ainsi que nous rendre compte de la complexité de celle-ci.",
      image: "../../assets/progra/csc-post-challenge.png",
      preuveImage: ["../../assets/CyberSecurityChallenge/cyber.png"],
      valoTime: "24h",
    },
    {
      url: "https://www.python.org/",
      title: "Formations Python, Data-Science et Machine-Learning",
      subtitle: "Pour le traitement de données",
      desc:
        "Ce language est particulièrement populaire pour l’analyse de données et l’intelligence artificielle, mais aussi pour le développement web backend et le computing scientifique." +
        " Python est aussi utilisé pour développer des outils de productivité, des jeux ou des applications." +
        " Des dizaines de milliers de sites web ont été développés avec ce language, au même titre que plusieurs applications très connues comme Dropbox, Netflix ou Spotify",
      benef:
        "Étant donné mon intérêt pour la Data-Science, le Python me permet d'avoir une première approche dans ce domaine. Je suis personnellement persuadé que ce language sera une porte pour le futur dans le domaine de la Data Science",
      image: "../../assets/progra/machine-learning.jpg",
      preuveImage: [
        "../../assets/formations/init Python Analyse donnees 12h.png",
        "../../assets/formations/lib Python Data Science 10h.png",
        "../../assets/formations/machine learning 10h.png",
        "../../assets/formations/python oriente objet 6h.png",
        "../../assets/formations/python perfectionement 4h.png",
        "../../assets/formations/python 4h.png",
        "../../assets/formations/pythoon data analysis 12h.png",
        "../../assets/formations/pythoon dev 40h.png",
        "../../assets/formations/R 12h.png",
        "../../assets/formations/algo 4h.png",
      ],
      valoTime: "80h",
    },
    {
      url: "https://docs.microsoft.com/en-us/dotnet/csharp/",
      title: "Formations C, C++ et C#",
      subtitle: "Pour avoir des bases classiques ",
      desc:
        "En ce qui concerne le C et le C ++, ils sont connus comme des language très puissants. C/C++ vous permettent d’avoir beaucoup de contrôle sur la façon dont vous utilisez les ressources informatiques." +
        " Grâce aux performances du C++, il est souvent utilisé pour développer des moteurs de jeu, des jeux et des applications de bureau." +
        " Enfin, le C# est un language de programmation généraliste créé par Microsoft afin de développer des applications sur Windows. Le language C# est bien construit, il est relativement intuitif et est très puissant.",
      benef:
        "Apprendre le C ainsi que ces dérivés mon permis d'obtenir une nouvelle vision sur la manière d'aborder certains problèmes. Leurs structures étant fort différentes (surtout pour le C) de ce dont j'avais l'habitude d'utiliser, comme languages jusqu'a présent.",
      image: "../../assets/progra/C.png",
      preuveImage: [
        "../../assets/formations/Cplusplus 50h.png",
        "../../assets/formations/Csharp oriente objet 20h.png",
        "../../assets/formations/Csharp 20h.png",
      ],
      valoTime: "90h",
    },
    {
      url: "https://www.apple.com/befr/swift/",
      title: "Formations developpement IOS en swift",
      subtitle: "Une entrée dans le monde mobile IOS",
      desc:
        "Swift, comme son nom ne l’indique pas forcément, est un language de programmation créé par Apple et destiné au développement d’applications pour les différents systèmes de la marque (MacOS, iOS, WatchOS et tvOS)." +
        " Il permet donc de développer des applications pour chacun des systèmes d’exploitation dudit écosystème, et peut être utilisé seul ou conjointement avec Objective-C, C et/ou C++. ",
      benef:
        "Une formation en Swift, bien que peu utilisé si l'on ne développe pas des application pour l'éco-systène IOS, permet de faire ses premiers pas avec un language très moderne. Ce qui fait de lui une superbe opportunitée pour découvrir une nouvelle manière de développer beaucoup plus au goût du jour, sans rester attaché à d'anciens principes.",
      image: "../../assets/progra/swift.jpg",
      preuveImage: [
        "../../assets/formations/dev iphone mvc 30h.png",
        "../../assets/formations/ios dev mobile 15h.png",
        "../../assets/formations/ios responsive 10h.png",
        "../../assets/formations/swift approfondi 20h.png",
        "../../assets/formations/swift 30h.png",
      ],
      valoTime: "105h",
    },
    {
      url: "https://nodejs.org/en/",
      title: "Formation NodeJS",
      subtitle: "Pour des applications Réseaux",
      desc:
        'Le NodeJS permet d\'écrire du Javascript du côté serveur, cela a pour intérêt de pour pouvoir réaliser des applications "FullStack" javascript,' +
        " ainsi que des micro services, et bien d'autres choses que uniquement du developpement web",
      benef:
        "Cette formation NodeJs m'a permis d'être tout simplement plus â l'aise avec ce languages fort utilisé en dévelopement web",
      image: "../../assets/progra/nodejs-logo.png",
      preuveImage: ["../../assets/formations/nodeJS 10h.png"],
      valoTime: "10h",
    },
    {
      url: "https://reactnative.dev/",
      title: "Formation ReactNative",
      subtitle: " Des Applications multit supports",
      desc:
        "React Native combine le développement d'applications natives avec le développement d'interface utilisateur JavaScript." +
        " Bien que des fonctionnalités informatiques lourdes puissent être implémentées avec des modules natifs pour iOS et Android, le reste du code peut être écrit avec JavaScript et partagé sur différentes plateformes." +
        " Contrairement aux outils multi-plateformes de Apache et Cordova, React Native n'utilise pas WebView, un moteur mobile qui encapsule du code. Au lieu de cela, il fournit un accès aux contrôleurs mobiles natifs, ce qui permet d'obtenir une apparence et une convivialité natives pour les applications.",
      benef:
        "Le React Native m'a permis de développer mes connaisances dans le domaine du développement d'applications mobiles. Plus standard que le swift, et plus répendu étant donné de sa compatibilité aussi bien pour les appareils IOS qu'Android. Cette formation m'a permis de réaliser de bout en bout une application, et ainsi prendre conscience de toutes les étapes de production de celle-ci.",
      image: "../../assets/progra/ReactNative.jpg",
      preuveImage: ["../../assets/formations/ReactNative 30h.png"],
      valoTime: "30h",
    },
    {
      url: "https://angular.io/",
      title: "Formation Angular",
      subtitle: "Des pages web modernes",
      desc:
        "Angular est un framework JavaScript développé par Google et reconnu pour être l'un des frameworks les plus complets." +
        " Il embarque en effet nativement nombre de fonctionnalités le qualifiant pour le développement d'applications web, même les plus complexes." +
        " Grâce à son évolution, Angular est cependant adapté à tout projet d'application de bureau ou mobile. Rapide à déployer et puissant, il sait convaincre petites et grandes entreprises.",
      benef:
        "Il est difficile de passer à côté d'un des framework de développement web les plus complets et les plus en vogues du moment pour un futur développeur web. Angular était tout indiqué étant donné sa nature moderne dans le domaine.",
      image: "../../assets/progra/angular.png",
      preuveImage: [
        "../../assets/formations/boottrap 10h.png",
        "../../assets/formations/Dev App Angular 20h.png",
      ],
      valoTime: "20h+",
    },
    {
      url: "https://www.docker.com/",
      title: "Formation Docker",
      subtitle: "Une solution d'avenir",
      desc:
        "La plateforme Docker présente de nombreux avantages. Elle permet de composer, de créer, de déployer et d’échelonner rapidement des containers sur les hôtes Docker." +
        " Elle offre aussi un haut degré de portabilité, ce qui permet aux utilisateurs de s’enregistrer et de partager des containers sur une large variété d’hôtes au sein d’environnements publics et privés.",
      benef:
        "Les bénéfices de la containerisation sont nombreux, c'est pourquoi , de nos jours, Docker est un incontournable de la boîte à outils du développeur. Que ce soit aussi bien pour une question de gestion des backups, d'efficacité du matériel ou encore de la \"Scalability\" du système à mettre en place.",
      image: "../../assets/progra/docker.png",
      preuveImage: ["../../assets/formations/Docker 8h.png"],
      valoTime: "8h",
    },
    {
      url: "https://aws.amazon.com/fr/what-is-aws/#:~:text=Amazon%20Web%20Services%20(AWS)%20est,de%20donn%C3%A9es%20du%20monde%20entier.",
      title: "Formation Amazon Web Services",
      subtitle: "Tout est dans le cloud",
      desc:
        "Le Cloud AWS est une plateforme de services cloud développée par le géant américain Amazon." +
        " AWS regroupe plus de 100 services répartis en diverses catégories telles que le stockage cloud, la puissance de calcul, l’analyse de données, l’intelligence artificielle ou même le développement de jeux vidéo." +
        " C’est grâce à Amazon Web Services qu'Amazon est devenu le leader mondial du cloud computing. Les services Amazon Cloud sont utilisés par des millions d’entreprises dans le monde entier comme Spotify, Yelp, Shazam ou encore AirBnB.",
      benef:
        "Amazon Web Service permet de mettre en place de nombreux services nécéssaires pour tout projets de développements informatique. De plus, nombre de ces services sont disponible en mode \"Server Less\" ce qui les rend de plus en plus au goût du jour. C'est pour toutes ces raisons qu'il était compliqué de passer à côté d'une telle infrastructure. ",
      image: "../../assets/other/AWS.png",
      preuveImage: ["../../assets/formations/AWS decouverte 5h.png"],
      valoTime: "20h",
    },
    {
      url: "https://www.adobe.com/be_fr/products/xd.html",
      title: "Formation AdobeXD",
      subtitle: "Pour des maquettes de qualités",
      desc: "Adobe XD est un logiciel d'UX/UI Design permettant aux webdesigners et aux infographistes de créer, et modifier très facilement des prototypes interactifs de sites web ou d'applications mobiles.",
      benef:
        "Une formation sur outils de présentations est un plus afin de pouvoir mettre en valeur ses idées, concepts et faciliter la communication avec le client ou l'utlisateur final de notre produit.",
      image: "../../assets/other/adobeXD.jpg",
      preuveImage: ["../../assets/formations/adobeXD 1h.png"],
      valoTime: "6h",
    },
    {
      url: "",
      title: "Matériel informatique",
      subtitle: "Une passion",
      desc:
        "Les connaissances en matériel informatique pour un développeur sont souvent présentes. Mais personellement cela en est devenu une passion." +
        " Que ce soit au niveau des ordinateurs, des souris, des claviers mais aussi des processeurs, cartes graphiques, cartes son ou encore les micros, caméras et autres. J'ai toujours aimé me tenir au courant des nouvelles technologies." +
        " J'ai déjà eu l'occasion de monter de bout en bout un ordinateur, allant du choix des pièces jusqu'à l'installation de son OS tout en passant par le montage en lui même.",
      image: "../../assets/materiel/computer.png",
      preuveImage: ["../../assets/materiel/computer.png"],
      valoTime: "10h",
    },
  ];

  ngOnInit(): void {}

  openPreuve(preuveImage: string[]): void {
    this.dialog.open(PortFolioPreuveComponent, {
      data: {
        imageURL: preuveImage,
      },
    });
  }
}
