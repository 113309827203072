import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatTabsModule} from '@angular/material/tabs';
import { ProfilComponent } from './profil/profil.component';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { ProfilCardComponent } from './profil-card/profil-card.component';
import { ProfilInfoComponent } from './profil-info/profil-info.component';
import {MatListModule} from '@angular/material/list';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import { QuoteComponent } from './quote/quote.component';
import { PortFolioPreuveComponent } from './port-folio-preuve/port-folio-preuve.component';
import {MatDialogModule} from '@angular/material/dialog';
import { RecapHeuresComponent } from './recap-heures/recap-heures.component';
import { ProfilCompetencesComponent } from './profil-competences/profil-competences.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatTableModule} from '@angular/material/table';

@NgModule({
  declarations: [
    AppComponent,
    ProfilComponent,
    PortfolioComponent,
    ProfilCardComponent,
    ProfilInfoComponent,
    QuoteComponent,
    PortFolioPreuveComponent,
    RecapHeuresComponent,
    ProfilCompetencesComponent,

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatTabsModule,
    MatButtonModule,
    MatListModule,
    MatChipsModule,
    MatIconModule,
    MatDialogModule,
    MatGridListModule,
    FlexLayoutModule,
    MatTableModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
