import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-profil-competences",
  templateUrl: "./profil-competences.component.html",
  styleUrls: ["./profil-competences.component.scss"],
})
export class ProfilCompetencesComponent implements OnInit {
  constructor() {}

  langageList = [
    {
      name: "HTML/CSS",
      image: "./assets/languages/html.png",
      url: "https://developer.mozilla.org/fr/docs/Web/HTML",
    },
    {
      name: "Java-Script",
      image: "./assets/languages/js.png",
      url: "https://developer.mozilla.org/fr/docs/Web/JavaScript",
    },
    {
      name: "Java",
      image: "./assets/languages/java.png",
      url: "https://www.java.com/fr/",
    },
    {
      name: "MySql",
      image: "./assets/languages/mysql.png",
      url: "https://www.mysql.com/fr/",
    },
    {
      name: "Postgresql",
      image: "./assets/languages/postgresql.png",
      url: "https://www.postgresql.org/",
    },
    {
      name: "Python",
      image: "./assets/languages/python.png",
      url: "https://www.python.org/",
    },
    {
      name: "Numpy",
      image: "./assets/languages/NumpyLogo.png",
      url: "https://numpy.org/",
    },
    {
      name: "Pandas",
      image: "./assets/languages/Pandas.png",
      url: "https://pandas.pydata.org/",
    },
    {
      name: "Amazon Web Services",
      image: "./assets/languages/AWS.jpg",
      url: "https://aws.amazon.com/fr/",
    },
    {
      name: "C",
      image: "./assets/languages/c.png",
      url: "https://fr.wikipedia.org/wiki/C_(langage)",
    },
    {
      name: "C++",
      image: "./assets/languages/c++.png",
      url: "https://fr.wikipedia.org/wiki/C%2B%2B",
    },
    {
      name: "C#",
      image: "./assets/languages/csharp.png",
      url: "https://docs.microsoft.com/en-us/dotnet/csharp/",
    },
    {
      name: "Angular",
      image: "./assets/languages/angular.png",
      url: "https://angular.io/",
    },
    {
      name: "ReactNative",
      image: "./assets/languages/react.png",
      url: "https://reactnative.dev/",
    },
    {
      name: "MatLab",
      image: "./assets/languages/matlab.png",
      url: "https://nl.mathworks.com/?s_tid=gn_logo",
    },
    {
      name: "NodeJS",
      image: "./assets/languages/nodejs.png",
      url: "https://nodejs.org/en/",
    },
  ];

  ngOnInit(): void {}
}
