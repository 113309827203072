<mat-card class="profile-card mat-elevation-z4">
  <mat-card-header>
    <mat-card-title>Lemer Guillaume</mat-card-title>
    <mat-card-subtitle> Etudiant Ephec Louvain-La-Neuve</mat-card-subtitle>
  </mat-card-header>
  <div id="profile-image-container">
    <img mat-card-image src="../../assets/profile.jpeg">
  </div>
  <mat-card-content>
    <p>
      Passionné de nouvelles technologies et des sciences, j'ai toujours été interssé par le développement informatique.
    </p>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-actions>
    <mat-list>
      <mat-list-item (click)="openLinkedin()">
        <mat-icon fontSet="fab" fontIcon="fa-linkedin"></mat-icon>
        <div mat-line> Profile Linkedin </div>
      </mat-list-item>
      <mat-list-item (click)="openGithub()">
        <mat-icon fontSet="fab" fontIcon="fa-github"></mat-icon>
        <div mat-line> Profile Github </div>
        <div mat-line></div>
      </mat-list-item>
      <mat-list-item (click)="downloadCV()">
        <mat-icon fontSet="fa" fontIcon="fa-download"></mat-icon>
        <div mat-line> CV </div>
        <div mat-line mat-subheader> version anglais </div>
      </mat-list-item>
      <mat-list-item (click)="downloadCVFR()">
        <mat-icon fontSet="fa" fontIcon="fa-download"></mat-icon>
        <div mat-line> CV </div>
        <div mat-line mat-subheader> version français </div>
      </mat-list-item>
    </mat-list>
  </mat-card-actions>
</mat-card>